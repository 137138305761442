@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li {
        @apply cursor-pointer
    }
}

html {
    margin: 0;
}

body {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #1b1b1b;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.bounce {
    animation: bounce 2.5s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}